import React, { useContext, useEffect, useRef, useState } from "react";
import usePageName from "../../../hocs/usePageName";
import {
  Box,
  Button,
  Card,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScroller from "../../../components/InfiniteScroller/InfiniteScroller";
import ListFooter from "../../../components/InfiniteScroller/ListFooter";
import TimeCardRow from "../TimeCards/TimeCardRow";
import ReactSignatureCanvas from "react-signature-canvas";
import ShopS3PresignedImage from "../../../components/ShopS3PresignedImage";
import moment from "moment/moment";
import { LoadingButton } from "@mui/lab";
import * as Sentry from "@sentry/react";

const TimeApproval = () => {
  usePageName({ heading: "Approve Time" });
  const navigate = useNavigate();
  const { id: timeCardApprovalId } = useParams();
  const signatureRef = useRef();
  const [timeCardApprovalRequest, setTimeCardApprovalRequest] = useState(null);
  const { authToken, ezorder } = useContext(AuthContext);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [modalState, setModalState] = useState({
    visible: false,
    selectedTimeCard: null,
  });

  const getTimeCardApprovalRequest = async () => {
    try {
      setIsRefreshing(true);
      console.log(
        `GET /shop/crew/time-card/approval-requests/${timeCardApprovalId}`
      );
      const response = await ezorder.get(
        `/shop/crew/time-card/approval-requests/${timeCardApprovalId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("RES", response.data);
      setTimeCardApprovalRequest(response.data.request);
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const handleApprove = async () => {
    let base64Signature = signatureRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    console.log("Signature base64Image", base64Signature);

    //setSignature(signature);
    const s3SignaturePath = await uploadSignature(base64Signature);
    console.log("Saved Signature S3 Path", s3SignaturePath);
    await signAndApprove(s3SignaturePath);
  };

  const createWritePresignedUrl = async (
    actionType,
    fileName,
    contentType,
    resource
  ) => {
    const response = await ezorder.post(
      "/files/presignedUrl",
      { actionType, fileName, contentType, resource },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response.data;
  };

  function base64toBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/jpeg" });
  }

  // Returns S3 Key generated during presigning
  const uploadSignature = async (base64Signature) => {
    try {
      setIsRefreshing(true);
      const presignedUrlResponse = await createWritePresignedUrl(
        "WRITE",
        "signature.png", // this name doesn't matter
        "image/png",
        "signatures"
      );
      const presignedUploadUrl = presignedUrlResponse.uploadUrl;
      const s3Key = presignedUrlResponse.key;

      //const fileBlob = await getBlob(signatureFile.uri);
      let fileBlob = base64toBlob(base64Signature);
      const uploadResponse = await fetch(presignedUploadUrl, {
        method: "PUT",
        body: fileBlob,
        headers: {
          "Content-Type": "image/png", // DO NOT PASS IN A BEARER TOKEN
        },
      });
      console.log("S3 Fetch Response", uploadResponse);
      if (!uploadResponse.ok) {
        Sentry.captureException(
          new Error(`Upload Failed. Response Status ${uploadResponse.status}`)
        );
        // For any other server error
        throw new Error(`Upload Failed ${uploadResponse.status}`);
      }

      // TODO If you get a weird error or non-error, AMAZON might be returning XML which fetch doesn't support...
      // SO IT WILL NOT THROW AN ERROR EVEN WITH STATUS 400
      // console.log("fetchResult " + (await fetchResult.text()).toString());
      // console.log("fetchResult " + fetchResult.toString());
      console.log("S3 Key of Uploaded Signature", s3Key);
      return s3Key;
    } catch (error) {
      console.log(error);
      return null;
    } finally {
      setIsRefreshing(false);
    }
  };

  const signAndApprove = async (signatureS3Path) => {
    try {
      setIsRefreshing(true);
      const response = await ezorder.put(
        `/shop/crew/time-card/approval-requests/${timeCardApprovalId}`,
        {
          signature: signatureS3Path,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("RES", response.data);
      setTimeCardApprovalRequest(response.data.signed);
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    getTimeCardApprovalRequest();
  }, []);

  if (!timeCardApprovalRequest) return null;

  return (
    <Box>
      {timeCardApprovalRequest.signedOn ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "16px",
          }}
        >
          <Box>
            <Typography>
              Signed On{" "}
              {moment(timeCardApprovalRequest.signedOn).format("MM/DD/YYYY")}
            </Typography>
            <Card sx={{ background: "#d2d2d2", width: 310, height: 100 }}>
              <ShopS3PresignedImage
                s3Key={timeCardApprovalRequest.signature}
                style={{ width: 310, height: 100 }}
              />
            </Card>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "16px",
          }}
        >
          <Box>
            <Typography>Sign Here</Typography>
            <Card sx={{ background: "#d2d2d2", width: 310, height: 100 }}>
              <ReactSignatureCanvas
                ref={signatureRef}
                backgroundColor="rgba(0, 0, 0, 0)"
                penColor="black"
                canvasProps={{
                  width: 310,
                  height: 100,
                  className: "sigCanvas",
                }}
              />
            </Card>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                loading={isRefreshing}
                onClick={() => {
                  signatureRef.current.clear();
                }}
              >
                Clear
              </LoadingButton>
              <LoadingButton
                loading={isRefreshing}
                onClick={() => {
                  handleApprove();
                }}
              >
                Approve
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      )}

      <InfiniteScroller
        // refreshControl={
        //   <RefreshControl
        //     refreshing={isRefreshing}
        //     onRefresh={() => {
        //       getWeeklyTimeCards(weekOf);
        //     }}
        //   />
        // }
        items={timeCardApprovalRequest.timeCards}
        renderItem={(item, index) => {
          return (
            <TimeCardRow
              key={index}
              timeCard={item}
              onClick={() => {
                //console.log("clicked");
                // navigation.navigate("Edit Time Card", {
                //   timeCardId: item.id,
                // });
              }}
            />
          );
        }}
        // onRefresh={onRefresh}
        onEndReached={() => {}}
        loading={isRefreshing}
        ListFooterComponent={() => {
          return <ListFooter hasMore={false} onClick={null} />;
        }}
      />
    </Box>
  );
};

export default TimeApproval;
