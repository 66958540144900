import {
  Box,
  TextField,
  Button,
  MenuItem,
  Avatar,
  Typography,
  Modal,
  Card,
  Checkbox,
  Select,
  InputLabel,
} from "@mui/material";
import CollapsableCard from "../../../../CollapseableCard";
import S3FileViewer from "../../../../S3FileViewer";
import { Upload } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import { useTheme } from "@emotion/react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import AdornedInput from "../../../../AdornedInput";
import { toThreeDecimals, toTwoDecimals } from "../../../calculations/utils";
import useAllColorsbyManufacturer from "../../../../../hooks/useAllColorsByManufacturer";
import usePaintManufacturers from "../../../../../hooks/usePaintManufacturers";
import * as Sentry from "@sentry/react";

const OrderDetails = ({
  details,
  action,
  isCustomMaterial,
  isCustomerSupplied,
  toggleCustomerSupplied,
}) => {
  const theme = useTheme();
  const { paintManufacturers } = usePaintManufacturers();
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const { colors, getColors } = useAllColorsbyManufacturer();
  const [selectedColor, setSelectedColor] = useState(null);
  const [coilWidthOptions, setCoilWidthOptions] = useState([]);

  const { authToken, ezorder } = useContext(AuthContext);
  const [taxFormModalOpen, setTaxFormModalOpen] = useState(false);
  const [taxFormModalFile, setTaxFormModalFile] = useState(null);
  const [newTaxFormName, setNewTaxFormName] = useState("");
  const [newTaxFormExpirationDate, setNewTaxFormExpirationDate] = useState(
    moment().format("MM/DD/YYYY")
  );
  const handleDate = (date) => {
    const expirationDate = moment(date).format("MM/DD/YYYY");
    setNewTaxFormExpirationDate(expirationDate);
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (type) => (e) => {
    switch (type) {
      case "poNumber":
        action({ type: "UPDATE DETAILS: PO#", payload: e.target.value });
        break;
      case "notes":
        action({ type: "UPDATE DETAILS: NOTES", payload: e.target.value });
        break;
      default:
        break;
    }
  };

  // get presignedUrl
  const createWritePresignedUrl = async (
    actionType,
    fileName,
    contentType,
    resource,
    identifier
  ) => {
    try {
      let presignedRequest = {
        actionType: actionType,
        fileName: fileName,
        contentType: contentType,
        resource: resource,
        identifier: identifier,
      };

      const response = await ezorder.post(
        `/files/presignedUrl`,
        presignedRequest,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.log(error);
      throw Error("Unable to load presigned URL for Trim image", {
        cause: error,
      });
    }
  };

  // uplaod image to S3 bucket
  const uploadTaxForm = async () => {
    setIsLoading(true);
    const identifier = details.company.id ? details.company.id : "GUEST";

    let actionType = "WRITE";
    let fileName = taxFormModalFile.name;
    let contentType = taxFormModalFile.type;
    let resource = "tax-forms";

    const presignedResponse = await createWritePresignedUrl(
      actionType,
      fileName,
      contentType,
      resource,
      identifier
    );
    let s3Key = presignedResponse.key;

    await fetch(presignedResponse.uploadUrl, {
      method: "PUT",
      body: taxFormModalFile,
    })
      .then(async (response) => {
        if (response.status === 200 || response.status == 201) {
          let newTaxForm = {
            name: newTaxFormName,
            file: s3Key,
            companyId: details.company.id ? details.company.id : null,
            type: "ANNUAL",
            expirationDate: newTaxFormExpirationDate,
            shouldSetAsCompanyDefault: true,
          };

          const saveResponse = await ezorder.post(
            `/admin/orders/${details.id}/tax-form`,
            newTaxForm,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );

          let taxForm = saveResponse.data.order.taxExemptForm;
          action({
            type: "UPDATE DETAILS: TAX EXEMPT FORM",
            payload: taxForm,
          });
          setNewTaxFormName("");
          setTaxFormModalFile(null);
          setTaxFormModalOpen(false);
        } else {
          Sentry.captureException(
            new Error(`Upload Failed. Response Status ${response.status}`)
          );
          // For any other server error
          throw new Error(`Upload Failed ${response.status}`);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        throw Error("Failed", { cause: error });
      });
  };

  const handleManufacturerChange = (e) => {
    // e.target.value is the INDEX of the manufacturer
    setSelectedManufacturer(paintManufacturers[e.target.value]);
    setCoilWidthOptions([]);

    let payload = {
      manufacturer: paintManufacturers[e.target.value].name,
      color: null,
      gauge: null,
      // // TODO: Need to set the value
      flatSheetCost: 0,
      flatSheetCostOriginal: 0,
      coilCostPerSqFt: 0,
      coilCostPerSqFtOriginal: 0,
      flatSheetSize: 48,
    };
    action({
      type: "SET ORDER STATE",
      payload: payload,
    });
  };

  const handleColorChange = (e) => {
    // e.target.value is the INDEX of the color
    setSelectedColor(colors[e.target.value]);

    // TODO: We need to load the coilWidthOptions
    let gaugeOptions = colors[e.target.value].gauges;

    let selectedGauge = gaugeOptions.find(
      (g) => parseFloat(g.gauge).toFixed(4) == 24
      // parseFloat(colors[e.target.value].gauges[0].gauge).toFixed(4)
    );
    if (!selectedGauge) {
      selectedGauge = gaugeOptions[0]; // Default to first option if 24 gauge doesnt exist
    }

    if (selectedGauge) {
      setCoilWidthOptions(selectedGauge.coilWidths);

      // Find selected coil width option by saved flatSheet size and update flatSheetCostOriginal
      let flatSheetSize = details.flatSheetSize;

      const index = selectedGauge.coilWidths.findIndex(
        (c) => parseFloat(c.width) == parseFloat(flatSheetSize)
      );
      let coilOptionSelected = selectedGauge.coilWidths[index];

      let payload = {
        color: colors[e.target.value].name,
        // manufacturer: colors[e.target.value].paintManufacturer.name, // color change should not update manufacturer
        // gauge: parseFloat(colors[e.target.value].gauges[0].gauge).toFixed(4),
        gauge: parseFloat(selectedGauge.gauge).toFixed(4),
        flatSheetCost: coilOptionSelected ? coilOptionSelected?.price : 0,
        flatSheetCostOriginal: coilOptionSelected
          ? coilOptionSelected?.price
          : 0,
        coilCostPerSqFt: coilOptionSelected
          ? coilOptionSelected.pricePerSqFt
          : 0,
        coilCostPerSqFtOriginal: coilOptionSelected
          ? coilOptionSelected.pricePerSqFt
          : 0,
      };

      if (isCustomerSupplied) {
        payload.flatSheetCost = 0;
        payload.flatSheetCostOriginal = 0;
        payload.coilCostPerSqFt = 0;
        payload.coilCostPerSqFtOriginal = 0;
      }

      action({
        // type: "UPDATE DETAILS: COLOR",
        type: "SET ORDER STATE",
        payload: payload,
      });
    } else {
      let payload = {
        color: colors[e.target.value].name,
        manufacturer: colors[e.target.value].paintManufacturer.name,
        gauge:
          colors[e.target.value] &&
          colors[e.target.value].gauges[0] &&
          colors[e.target.value].gauges[0].gauge
            ? parseFloat(colors[e.target.value].gauges[0].gauge).toFixed(4)
            : 24,
        // TODO: Need to set the value
        flatSheetCost: 0,
        flatSheetCostOriginal: 0,
        coilCostPerSqFt: 0,
        coilCostPerSqFtOriginal: 0,
        flatSheetSize: 48,
      };
      action({
        // type: "UPDATE DETAILS: COLOR",
        type: "SET ORDER STATE",
        payload: payload,
      });
    }
  };

  const handleGaugeChange = (gaugeValue) => {
    let selectedGauge = selectedColor.gauges.find(
      (g) => parseFloat(g.gauge) == parseFloat(gaugeValue)
    );
    if (selectedGauge) {
      setCoilWidthOptions(selectedGauge.coilWidths);
    }

    // Find selected coil width option by saved flatSheet size and update flatSheetCostOriginal
    let flatSheetSize = details.flatSheetSize; // default 48.00
    // Set selected coil with to 48 inches if available
    const index = selectedGauge.coilWidths.findIndex(
      (c) => parseFloat(c.width) == parseFloat(flatSheetSize)
    );
    let coilOptionSelected = selectedGauge.coilWidths[index];

    let payload = {
      gauge: parseFloat(gaugeValue).toFixed(4),
      flatSheetCost: coilOptionSelected ? coilOptionSelected?.price : 0,
      flatSheetCostOriginal: coilOptionSelected ? coilOptionSelected?.price : 0,
      coilCostPerSqFt: coilOptionSelected
        ? coilOptionSelected?.pricePerSqFt
        : 0,
      coilCostPerSqFtOriginal: coilOptionSelected
        ? coilOptionSelected?.pricePerSqFt
        : 0,
    };

    if (isCustomerSupplied) {
      payload.flatSheetCost = 0;
      payload.flatSheetCostOriginal = 0;
      payload.coilCostPerSqFt = 0;
      payload.coilCostPerSqFtOriginal = 0;
    }

    action({
      // type: "UPDATE DETAILS: COLOR",
      type: "SET ORDER STATE",
      payload: payload,
    });
  };

  const handleFSOptionSelect = (fsWidthOption) => {
    const index = coilWidthOptions.findIndex((c) => c.width == fsWidthOption);
    let coilOptionSelected = coilWidthOptions[index];

    // UPDATE FLATSHEET SIZE AND COST VARIABLES WHEN A COIL OPTION IS SELECTED
    let payload = {
      flatSheetSize: fsWidthOption,
      flatSheetCost: coilOptionSelected ? coilOptionSelected?.price : 0,
      flatSheetCostOriginal: coilOptionSelected ? coilOptionSelected?.price : 0,
      coilCostPerSqFt: coilOptionSelected
        ? coilOptionSelected?.pricePerSqFt
        : 0,
      coilCostPerSqFtOriginal: coilOptionSelected
        ? coilOptionSelected?.pricePerSqFt
        : 0,
    };

    if (isCustomerSupplied) {
      payload.flatSheetCost = 0;
      payload.flatSheetCostOriginal = 0;
      payload.coilCostPerSqFt = 0;
      payload.coilCostPerSqFtOriginal = 0;
    }

    action({
      type: "SET ORDER STATE",
      payload: payload,
    });
  };

  // for manually typing the FS width/size
  const handleFSSizeChange = (fsWidthEntered) => {
    let flatSheetSize = parseFloat(fsWidthEntered);
    const index = coilWidthOptions.findIndex((c) => c.width == flatSheetSize);
    let coilOptionSelected = coilWidthOptions[index];
    let payload = {
      flatSheetSize: flatSheetSize,
      flatSheetCost: coilOptionSelected ? coilOptionSelected?.price : 0,
      flatSheetCostOriginal: coilOptionSelected ? coilOptionSelected?.price : 0,
      coilCostPerSqFt: coilOptionSelected
        ? coilOptionSelected?.pricePerSqFt
        : 0,
      coilCostPerSqFtOriginal: coilOptionSelected
        ? coilOptionSelected?.pricePerSqFt
        : 0,
    };

    if (isCustomerSupplied) {
      payload.flatSheetCost = 0;
      payload.flatSheetCostOriginal = 0;
      payload.coilCostPerSqFt = 0;
      payload.coilCostPerSqFtOriginal = 0;
    }

    action({
      type: "SET ORDER STATE",
      payload: payload,
    });
  };

  const setInitialSelectorOptionsAndInitialPrices = (e) => {
    // Seting the initial color will set the gauge options
    setSelectedColor(colors[e.target.value]);
    let gaugeOptions = colors[e.target.value].gauges;
    let selectedGauge = gaugeOptions.find(
      (g) =>
        parseFloat(g.gauge) ==
        // parseFloat(colors[e.target.value].gauges[0].gauge).toFixed(4)
        parseFloat(details.gauge).toFixed(4) // This should be based on the order gauge
    );
    if (
      selectedGauge // Check if current order gauge value exists as an option
    ) {
      setCoilWidthOptions(selectedGauge.coilWidths);

      // Find selected coil width option by saved flatSheet size and update flatSheetCostOriginal
      let flatSheetSize = details.flatSheetSize; // default 48.00

      // Set selected coil with to 48 inches if available
      const index = selectedGauge.coilWidths.findIndex(
        (c) => parseFloat(c.width) == parseFloat(flatSheetSize)
      );
      let coilOptionSelected = selectedGauge.coilWidths[index];

      // UPDATE FLATSHEET SIZE AND COST VARIABLES WHEN A COIL OPTION IS SELECTED
      let payload = {
        flatSheetCost: coilOptionSelected ? coilOptionSelected?.price : 0,
        flatSheetCostOriginal: coilOptionSelected
          ? coilOptionSelected?.price
          : 0,
        coilCostPerSqFt: coilOptionSelected
          ? coilOptionSelected.pricePerSqFt
          : 0,
        coilCostPerSqFtOriginal: coilOptionSelected
          ? coilOptionSelected.pricePerSqFt
          : 0,
      };
      if (isCustomerSupplied) {
        payload.flatSheetCost = 0;
        payload.flatSheetCostOriginal = 0;
        payload.coilCostPerSqFt = 0;
        payload.coilCostPerSqFtOriginal = 0;
      }

      action({
        type: "SET ORDER STATE",
        payload: payload,
      });
    } else {
      console.log("__gauge value does not exist as an option");
    }
  };

  // Initial Selector Value Setup without Updating price values if values already exist
  const setInitialSelectorOptions = (e) => {
    // Seting the initial color will set the gauge options
    setSelectedColor(colors[e.target.value]);
    let gaugeOptions = colors[e.target.value].gauges;

    let selectedGauge = gaugeOptions.find(
      (g) =>
        parseFloat(g.gauge) ==
        // parseFloat(colors[e.target.value].gauges[0].gauge).toFixed(4)
        parseFloat(details.gauge).toFixed(4) // this should be based on the order gauge
    );
    if (selectedGauge) {
      setCoilWidthOptions(selectedGauge.coilWidths);

      // Find selected coil width option by saved flatSheet size and update flatSheetCostOriginal
      let flatSheetSize = details.flatSheetSize; // default 48.00

      const index = selectedGauge.coilWidths.findIndex(
        (c) => parseFloat(c.width) == parseFloat(flatSheetSize)
      );
      let coilOptionSelected = selectedGauge.coilWidths[index];
    }
  };

  const findColorIndex = (name, manufacturer) => {
    return colors.findIndex(
      (c) => c.name === name && c.paintManufacturer.name === manufacturer
    );
  };

  // Update  Gauge Options based on Color Selection
  // ONLY UPDATE WHEN THERE IS NO SET VALUES
  useEffect(() => {
    if (isCustomerSupplied) {
      let payload = {
        flatSheetCost: 0,
        flatSheetCostOriginal: 0,
        coilCostPerSqFt: 0,
        coilCostPerSqFtOriginal: 0,
      };

      action({
        type: "SET ORDER STATE",
        payload: payload,
      });
    } else if (colors.length > 0) {
      if (details) {
        if (
          details.color
          // && details.manufacturer // details.manufacturer is required to load colors already
        ) {
          if (
            details.flatSheetCost == null &&
            details.flatSheetCostOriginal == null &&
            details.coilCostPerSqFt == null &&
            details.coilCostPerSqFtOriginal == null
          ) {
            // IF PRICES ARE NULL (FRESH ORDER)
            // IF COLOR IS SELECTED, USE COLOR, IF NOT PICK FIRST COLOR IN THE COLOR LIST
            // SET THE SELECTOR (COLOR, GAUGE) AND UPDATE PRICE
            let matchedColorIndex = findColorIndex(
              details.color,
              details.manufacturer
            );

            let e = {
              target: {
                value: matchedColorIndex > -1 ? matchedColorIndex : 0,
              },
            };
            setInitialSelectorOptionsAndInitialPrices(e);
          } else {
            // IF PRICES ARE NOT NULL (ORDER HAS BEEN SAVED AT LEAST ONCE BY ADMIN)
            // IF COLOR IS SELECTED, USE COLOR, IF NOT PICK FIRST COLOR IN THE COLOR LIST
            // SET THE SELECTOR (COLOR, GAUGE) WITHOUT UPDATING PRICE

            let matchedColorIndex = findColorIndex(
              details.color,
              details.manufacturer
            );
            let e = {
              target: {
                value: matchedColorIndex > -1 ? matchedColorIndex : 0,
              },
            };
            setInitialSelectorOptions(e);
          }
        }
        // IF NO COLOR OR MANUFACTURER SELECTED, JUST PICK THE FIRST OPTION IN THE COLOR LIST
        else {
          // ### NO LONGER SELECT THE FIRST ONE AUTOMATICALLY
          // If no value (creating new order), add initial values as well
          // let e = {
          //   target: {
          //     value: 0, // value is color index
          //   },
          // };
          // handleColorChange(e);
        }
      }
    }
  }, [
    colors,
    details.color,
    // details.manufacturer, // details.manufacturer is required to load colors already
    isCustomerSupplied,
  ]);
  // The change in color and manufacturer on handleColorChange makes this call twice

  // if (colors.length == 0 || !details.color || !details.manufacturer) {
  //   return null;
  // }

  // Load Color List When Manufacturers are loaded
  useEffect(() => {
    if (paintManufacturers) {
      let initialPaintManufacturerIndex = paintManufacturers
        .map((manufacturer) => manufacturer.name)
        .indexOf(details.manufacturer);
      let initialPaintManufacturer =
        paintManufacturers[initialPaintManufacturerIndex];
      console.log("INITIAL PAINT MANUFACTURER", initialPaintManufacturer);
      if (initialPaintManufacturer) getColors(initialPaintManufacturer.id);
    }
  }, [paintManufacturers, details.manufacturer]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Typography variant="h6">Color & Material</Typography>
      {details.isCustomMaterial ? (
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          <TextField
            variant="outlined"
            label="Custom Color"
            name="Custom Color"
            size="small"
            fullWidth
            value={details.customColor ? details.customColor : ""}
            onChange={(e) => {
              action({
                type: "UPDATE DETAILS: CUSTOM COLOR",
                payload: e.target.value,
              });
            }}
          />
          <TextField
            variant="outlined"
            label="Custom Manufacturer"
            name="Custom Manufacturer"
            size="small"
            fullWidth
            value={details.customManufacturer ? details.customManufacturer : ""}
            onChange={(e) => {
              action({
                type: "UPDATE DETAILS: CUSTOM MANUFACTURER",
                payload: e.target.value,
              });
            }}
          />
          <TextField
            variant="outlined"
            label="Custom Gauge"
            name="Custom Gauge"
            size="small"
            fullWidth
            value={details.customGauge ? details.customGauge : ""}
            onChange={(e) => {
              action({
                type: "UPDATE DETAILS: CUSTOM GAUGE",
                payload: e.target.value,
              });
            }}
          />
        </Box>
      ) : (
        <>
          {details.color && details.manufacturer && details.gauge && (
            <Typography>
              {details.color} - {details.manufacturer}, Gauge: {details.gauge}
            </Typography>
          )}

          <TextField
            select
            label="Manufacturer"
            value={paintManufacturers
              .map((manufacturer) => manufacturer.name)
              .indexOf(details.manufacturer)}
            onChange={handleManufacturerChange}
          >
            {paintManufacturers.map((manufacturer, index) => (
              <MenuItem key={manufacturer.id} value={index}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {manufacturer.name}
                </Box>
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Color"
            value={colors
              .map((c) => c.name + c.paintManufacturer.name)
              .indexOf(details.color + details.manufacturer)}
            onChange={handleColorChange}
          >
            {colors.map((theColor, index) => (
              <MenuItem key={theColor.id} value={index}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Avatar
                    src={theColor.image}
                    sx={{
                      width: 24,
                      height: 24,
                      marginRight: "1rem",
                    }}
                  />{" "}
                  {theColor.name} - {theColor.paintManufacturer.name} - [
                  {theColor.status}]
                </Box>
              </MenuItem>
            ))}
          </TextField>

          <TextField
            disabled={!details.color}
            select
            label="Gauge"
            value={parseFloat(parseFloat(details.gauge).toFixed(4)).toString()}
            onChange={(e) => {
              handleGaugeChange(e.target.value);
            }}
          >
            {selectedColor?.gauges.map((theGauge, index) => (
              <MenuItem
                key={theGauge.id}
                value={parseFloat(
                  parseFloat(theGauge.gauge).toFixed(4)
                ).toString()}
              >
                {parseFloat(parseFloat(theGauge.gauge).toFixed(4)).toString()}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          magin: 0,
          padding: 0,
        }}
      >
        <Checkbox
          style={{
            magin: 0,
            padding: 0,
          }}
          checked={isCustomMaterial}
          onChange={(e) => {
            action({
              type: "UPDATE DETAILS: IS CUSTOM MATERIAL",
              payload: e.target.checked,
            });
          }}
        />
        <Typography>Custom Material</Typography>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          magin: 0,
          padding: 0,
          marginRight: "16px",
        }}
      >
        <Checkbox
          style={{
            magin: 0,
            padding: 0,
          }}
          checked={isCustomerSupplied}
          onChange={(e) => {
            // toggleItemized(e.target.checked);
            toggleCustomerSupplied(e.target.checked);
          }}
        />
        <Typography>
          Flat Sheet and Coil Supplied by Customer (Sets FS and Coil Price to
          zero)
        </Typography>
      </div>
      {!isCustomMaterial && (
        <TextField
          sx={{ width: 400 }}
          size="small"
          label="Material Width"
          select
          value={parseFloat(details.flatSheetSize)}
          onChange={(e) => {
            handleFSOptionSelect(e.target.value);
          }}
        >
          {coilWidthOptions?.map((coilWidth, index) => (
            <MenuItem key={index} value={parseFloat(coilWidth.width)}>
              {coilWidth.width} in. - ${coilWidth.price} Per FS, $
              {coilWidth.pricePerSqFt} Per Sq Ft
            </MenuItem>
          ))}
        </TextField>
      )}
      {isCustomMaterial && (
        <TextField
          onFocus={(e) => e.target.select()}
          label="FS Size"
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          value={details.flatSheetSize}
          onChange={(e) => {
            handleFSSizeChange(e.target.value);
          }}
        />
      )}
      <AdornedInput
        onFocus={(e) => e.target.select()}
        label="FS Price"
        value={`${details?.flatSheetCost}`}
        type="number"
        onWheel={(event) => {
          event.preventDefault();
        }}
        onChange={(e) => {
          let payload = {
            flatSheetCost: parseFloat(e.target.value),
          };
          action({
            type: "SET ORDER STATE",
            payload: payload,
          });
        }}
        isOveridden={details?.flatSheetCost != details.flatSheetCostOriginal}
        handleReset={(e) => {
          // Set FS Price to FS Price Original
          let payload = {
            flatSheetCost: details.flatSheetCostOriginal,
          };

          action({
            type: "SET ORDER STATE",
            payload: payload,
          });
        }}
        symbol="$"
        position="start"
      />

      <AdornedInput
        onFocus={(e) => e.target.select()}
        label="Coil Price Per Sq Ft"
        value={`${details?.coilCostPerSqFt}`}
        type="number"
        onWheel={(event) => {
          event.preventDefault();
        }}
        onChange={(e) => {
          let payload = {
            coilCostPerSqFt: parseFloat(e.target.value),
          };
          action({
            type: "SET ORDER STATE",
            payload: payload,
          });
        }}
        isOveridden={
          details?.coilCostPerSqFt != details.coilCostPerSqFtOriginal
        }
        handleReset={(e) => {
          // Set FS Price to FS Price Original
          let payload = {
            coilCostPerSqFt: details.coilCostPerSqFtOriginal,
          };

          action({
            type: "SET ORDER STATE",
            payload: payload,
          });
        }}
        symbol="$"
        position="start"
      />

      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <TextField
          multiline
          rows={3}
          variant="outlined"
          label="Notes"
          id="notes"
          name="notes"
          fullWidth
          value={details.notes}
          onChange={handleChange("notes")}
        />
      </Box>
      <Box sx={{}}>
        <Typography variant="h6" sx={{ marginBottom: "8px" }}>
          Add Skid Charge
        </Typography>
        <AdornedInput
          onFocus={(e) => e.target.select()}
          label="Skid Charge"
          value={`${details?.skidCharge}`}
          type="number"
          onWheel={(event) => {
            event.preventDefault();
          }}
          onChange={(e) => {
            let payload = {
              skidCharge: toTwoDecimals(e.target.value),
            };
            action({
              type: "SET ORDER STATE",
              payload: payload,
            });
          }}
          symbol="$"
          position="start"
        />
      </Box>

      <CollapsableCard title="Tax Exempt Form" defaultOpen={false}>
        <Box>
          <Button
            component="label"
            size="small"
            variant="outlined"
            endIcon={<Upload />}
            onClick={() => {
              setTaxFormModalOpen(true);
            }}
          >
            New Tax Form
          </Button>
          <Modal
            open={taxFormModalOpen}
            onClose={() => {
              setNewTaxFormName("");
              setTaxFormModalFile(null);
              setTaxFormModalOpen(false);
              setNewTaxFormExpirationDate(moment());
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                background: theme.palette.secondary.main,
                boxShadow: 24,
                p: 4,
              }}
            >
              <Box
                sx={{
                  display: " flex",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <Box>
                  <TextField
                    label="Name"
                    fullWidth
                    value={newTaxFormName}
                    onChange={(e) => {
                      setNewTaxFormName(e.target.value);
                    }}
                  />
                  <div style={{ marginTop: "8px" }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        disabled={isLoading}
                        label="Expiration Date"
                        value={newTaxFormExpirationDate}
                        onChange={(newValue) => {
                          handleDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField fullWidth sx={{}} {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: " flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: " flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    {taxFormModalFile && (
                      <Typography>{taxFormModalFile.name}</Typography>
                    )}
                    <Button
                      component="label"
                      size="small"
                      variant="outlined"
                      endIcon={<Upload />}
                    >
                      <input
                        hidden
                        accept="image/*, application/pdf"
                        type="file"
                        //onChange={handleImage}
                        onChange={(e) => {
                          setTaxFormModalFile(e.target.files[0]);
                        }}
                      />
                      File
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: " flex",
                    flexDirection: "row",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    disabled={isLoading}
                    color="success"
                    onClick={() => {
                      uploadTaxForm();
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          {details.taxExemptForm ? (
            <div style={{ marginTop: "16px" }}>
              <TextField
                sx={{ marginRight: 1 }}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                label="Name"
                value={details.taxExemptForm.name}
              />
              <TextField
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                label="Expiration Dat"
                value={moment(details.taxExemptForm?.expirationDate).format(
                  "MM/DD/YYYY"
                )}
              />

              <S3FileViewer s3Key={details.taxExemptForm.file} />
            </div>
          ) : (
            <Typography>No Tax Exempt Form</Typography>
          )}
        </Box>
      </CollapsableCard>
    </Box>
  );
};
export default OrderDetails;
