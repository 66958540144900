import * as Sentry from "@sentry/react";

const useFileUpload = (ezorder, authToken) => {
  const getPresignedUrl = async (identifier, resource, file) => {
    let presignedRequest = {
      actionType: "WRITE",
      identifier: identifier,
      fileName: file.name,
      contentType: file.type,
      resource: resource,
    };

    const presignedResponse = await ezorder.post(
      `/files/presignedUrl`,
      presignedRequest,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    // console.log("presignedResponse", presignedResponse);
    return presignedResponse;
  };

  // uplaod image to S3 bucket
  const uploadFile = async (identifier, resource, file) => {
    const presignedUrl = await getPresignedUrl(identifier, resource, file);
    const { uploadUrl, key } = presignedUrl.data;
    // console.log("uploadUrl, key", uploadUrl, key);
    const uploadResponse = await fetch(uploadUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type, // DO NOT PASS IN A BEARER TOKEN
      },
    });

    console.log("S3 Fetch Response", uploadResponse);
    if (!uploadResponse.ok) {
      Sentry.captureException(
        new Error(`Upload Failed. Response Status ${uploadResponse.status}`)
      );
      // For any other server error
      throw new Error(`Upload Failed ${uploadResponse.status}`);
    }

    return key;
  };

  return { uploadFile };
};

export default useFileUpload;
