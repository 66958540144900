import { Box, Button } from "@mui/material";
import React, { useContext } from "react";
import * as Sentry from "@sentry/react";
import { AuthContext } from "../../../context/AuthContext";
import { SnackAlertContext } from "../../../context/SnackAlertContext";

const SentryTest = () => {
  const { authToken, ezorder } = useContext(AuthContext);
  const { openSnackMessage } = useContext(SnackAlertContext);

  const testAPI = async (order) => {
    try {
      const authHeader = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const res = await ezorder.put(`/app/health`, order, authHeader);
      console.log(res);
      if (res.status == 200 || res.status == 201) {
        openSnackMessage("success", "Updated!");
      }
      //  Handle 4XX errors here - Bad Request "errors" should be manually handled here
      /* 
        400 errors
        These errors indicate that the request was invalid due to a user error, such as incorrect input or improper formatting. 
        The client should fix the error and not retransmit the same request.
      */
      else {
        throw new Error(res.message);
      }
    } catch (error) {
      /* 
        500 errors - Something went wrong
        These errors indicate that the server encountered an unexpected issue while trying to process the request. 
        The request was accepted, but the server was unable to fulfill it. 
        The client can continue and try again with the request without modification
      */
      Sentry.captureException(error);
      console.log(error);

      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        openSnackMessage("error", error.response.data.error);
      } else {
        // Something went really bad
        console.log(error);
        openSnackMessage("error", error);
      }
    }
  };

  return (
    <Box>
      <Button
        onClick={() => {
          try {
            console.log("Testing Sentry");
            throw new Error("Testing Sentry");
          } catch (error) {
            Sentry.captureException(error);
          }
        }}
      >
        Test Sentry
      </Button>
      <Button
        onClick={() => {
          testAPI();
        }}
      >
        Test Sentry API
      </Button>
    </Box>
  );
};

export default SentryTest;
