import { useContext, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  TextField,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Container,
  Card,
} from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../../context/AuthContext";
import usePageName from "../../../hocs/usePageName";
import { useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import * as Sentry from "@sentry/react";

const AddAnnualTaxForm = ({}) => {
  usePageName({
    heading: "Do you have a tax exemption form (AZ5005)?",
    caption: "If yes, upload tax form below",
  });
  const navigate = useNavigate();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const { authToken, ezorder } = useContext(AuthContext);

  const [taxForm, setTaxForm] = useState({
    name: "",
    type: "ANNUAL",
    fileBlob: null,
    expirationDate: new Date(),
  });

  const handleFormChange = (key) => (e) => {
    const value =
      key === "fileBlob"
        ? e.target.files[0]
        : key === "type"
        ? e.target.name
        : e.target.value;
    setTaxForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleDate = (date) => {
    const expirationDate = moment(date).format("YYYY-MM-DD");
    setTaxForm((prev) => ({ ...prev, expirationDate }));
  };

  const createWritePresignedUrl = async (
    actionType,
    fileName,
    contentType,
    resource
  ) => {
    const data = { actionType, fileName, contentType, resource };
    console.log("Create Write Presigned URL", data);

    const response = await ezorder.post(
      "/files/presignedUrl",
      { actionType, fileName, contentType, resource },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response.data;
  };

  const handleSaveTaxForm = async (
    fileBlob,
    name,
    expirationDate,
    taxFormType
  ) => {
    try {
      setIsLoading(true);
      const presignResponse = await createWritePresignedUrl(
        "WRITE",
        fileBlob.name,
        fileBlob.type,
        "tax-forms"
      );

      const presignedUrl = presignResponse.uploadUrl;
      const s3Key = presignResponse.key;

      const uploadResponse = await fetch(presignedUrl, {
        method: "PUT",
        body: fileBlob,
        headers: {
          "Content-Type": fileBlob.type, // DO NOT PASS IN A BEARER TOKEN
        },
      });
      console.log("S3 Fetch Response", uploadResponse);
      if (!uploadResponse.ok) {
        Sentry.captureException(
          new Error(`Upload Failed. Response Status ${uploadResponse.status}`)
        );
        // For any other server error
        throw new Error(`Upload Failed ${uploadResponse.status}`);
      }
      const newTaxForm = {
        name,
        type: taxFormType,
        expirationDate: expirationDate,
        file: s3Key,
        setAsCompanyDefault: true,
      };
      console.log("newTaxForm", newTaxForm);

      const saveResponse = await ezorder.post(`/tax-forms`, newTaxForm, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      console.log("saveResponse", saveResponse);
      //   handleClose();
      navigate("/");
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="xs" sx={{ paddingBottom: "24px" }}>
      <Card
        sx={{
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Button
          disabled={isLoading}
          variant="outlined"
          component="label"
          onChange={handleFormChange("fileBlob")}
        >
          <UploadIcon sx={{ mr: "1rem" }} />
          Upload Tax Form
          <input hidden accept="image/*,.pdf" type="file" />
        </Button>
        {taxForm.fileBlob && <Typography>{taxForm.fileBlob.name}</Typography>}

        <TextField
          disabled={isLoading}
          fullWidth
          label="Form Name"
          onChange={handleFormChange("name")}
        />
        {/* <TextField
          fullWidth
          label="Expiration Date"
          onChange={handleFormChange("expirationDate")}
        /> */}
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            disabled={isLoading}
            label="Expiration Date"
            value={taxForm.expirationDate}
            onChange={(newValue) => {
              handleDate(newValue);
            }}
            renderInput={(params) => (
              <TextField fullWidth sx={{}} {...params} />
            )}
          />
        </LocalizationProvider>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            disabled={isLoading}
            variant="outlined"
            onClick={() => {
              navigate("/");
            }}
          >
            Skip
          </Button>
          <Button
            disabled={isLoading}
            sx={{
              marginLeft: "8px",
            }}
            variant="contained"
            onClick={() => {
              const { fileBlob, name, expirationDate, type } = taxForm;
              if (!name || !type || !fileBlob) return;
              handleSaveTaxForm(fileBlob, name, expirationDate, type);
            }}
          >
            Save
          </Button>
        </div>
      </Card>
    </Container>
  );
};
export default AddAnnualTaxForm;
