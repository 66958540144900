import AddTaxFormView from "./View";

import { useContext, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import moment from "moment";
import * as Sentry from "@sentry/react";

const AddTaxFormModal = ({ open, handleOpen, handleClose }) => {
  const { authToken, ezorder } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [taxForm, setTaxForm] = useState({
    name: "",
    type: "ANNUAL",
    fileBlob: null,
    expirationDate: new Date(),
  });

  const handleFormChange = (key) => (e) => {
    const value =
      key === "fileBlob"
        ? e.target.files[0]
        : key === "type"
        ? e.target.name
        : e.target.value;
    setTaxForm((prev) => ({ ...prev, [key]: value }));
  };

  const handleDate = (date) => {
    const expirationDate = moment(date).format("YYYY-MM-DD");
    setTaxForm((prev) => ({ ...prev, expirationDate }));
  };

  const createWritePresignedUrl = async (
    actionType,
    fileName,
    contentType,
    resource
  ) => {
    const data = { actionType, fileName, contentType, resource };
    console.log("Create Write Presigned URL", data);

    const response = await ezorder.post(
      "/files/presignedUrl",
      { actionType, fileName, contentType, resource },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response.data;
  };

  const handleSaveTaxForm = async (fileBlob, name, taxFormType) => {
    try {
      setLoading(true);
      // let fileType = fileBlob.uri.split(".").pop();
      // let fileType = fileBlob.type.split("/").pop();
      // let contentType;
      // if (fileType === "pdf") {
      //   contentType = "application/" + fileType;
      // } else {
      //   contentType = "image/" + fileType;
      // }
      const presignResponse = await createWritePresignedUrl(
        "WRITE",
        fileBlob.name,
        fileBlob.type,
        "tax-forms"
      );

      const presignedUrl = presignResponse.uploadUrl;
      const s3Key = presignResponse.key;

      const uploadResponse = await fetch(presignedUrl, {
        method: "PUT",
        body: fileBlob,
        headers: {
          "Content-Type": fileBlob.type, // DO NOT PASS IN A BEARER TOKEN
        },
      });
      console.log("S3 Fetch Response", uploadResponse);
      if (!uploadResponse.ok) {
        Sentry.captureException(
          new Error(`Upload Failed. Response Status ${uploadResponse.status}`)
        );
        // For any other server error
        throw new Error(`Upload Failed ${uploadResponse.status}`);
      }
      const newTaxForm = {
        name,
        type: taxFormType,
        file: s3Key,
        expirationDate: taxForm.expirationDate,
      };
      console.log("newTaxForm", newTaxForm);

      const saveResponse = await ezorder.post(`/tax-forms`, newTaxForm, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });

      console.log("saveResponse", saveResponse);
      handleClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AddTaxFormView
        formObject={taxForm}
        handleFormChange={handleFormChange}
        handleSaveTaxForm={handleSaveTaxForm}
        handleDate={handleDate}
        loading={loading}
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
    </>
  );
};
export default AddTaxFormModal;
