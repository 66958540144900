import * as Sentry from "@sentry/react";

export const getPresignedUrl =
  (ezorder, authToken, order) => (resource) => async (file) => {
    try {
      const identifier =
        order.company && order.company.id ? order.company.id : "GUEST";
      let presignedRequest = {
        actionType: "WRITE",
        identifier: identifier,
        fileName: file.name,
        contentType: file.type,
        resource: resource,
      };

      const presignedResponse = await ezorder.post(
        `/files/presignedUrl`,
        presignedRequest,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      return presignedResponse;
    } catch (error) {
      console.log(error);
      throw Error("Unable to load presigned URL for Trim image", {
        cause: error,
      });
    }
  };

// uplaod image to S3 bucket
export const uploadImage = (file) => async (presignedUrl) => {
  const { uploadUrl, key } = presignedUrl.data;
  const uploadResponse = await fetch(uploadUrl, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": file.type, // DO NOT PASS IN A BEARER TOKEN
    },
  });
  console.log("S3 Fetch Response", uploadResponse);
  if (!uploadResponse.ok) {
    Sentry.captureException(
      new Error(`Upload Failed. Response Status ${uploadResponse.status}`)
    );
    // For any other server error
    throw new Error(`Upload Failed ${uploadResponse.status}`);
  }
  return uploadResponse;
};
