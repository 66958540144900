import * as Sentry from "@sentry/react";

// uplaod image to S3 bucket
const uploadImage = async (uploadUrl, file) => {
  await fetch(uploadUrl, {
    method: "PUT",
    body: file,
  }).then((response) => {
    // console.log(response);
    if (response.status === 200 || response.status == 201) {
      return response;
    } else {
      Sentry.captureException(
        new Error(`Upload Failed. Response Status ${response.status}`)
      );
      // For any other server error
      throw new Error(`Upload Failed ${response.status}`);
    }
  });
  // .catch((error) => {
  //   throw Error("Failed to upload BenchWork image.", { cause: error });
  //   // console.log(error);
  // });
};

export default uploadImage;
